import { redEvent } from '@/lib/red-hooks'

const email = localStorage.getItem('email')

// Key: <PageName/component Name>_<Action>
export const clickOnProfileAvatar = (data?: any) => {
    redEvent.push({
        key: 'PROFILE_AVATAR_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const clickOnViewProfileOption = (data?: any) => {
    redEvent.push({
        key: 'VIEW_PROFILE_OPTION_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const clickOnLogoutOption = (data?: any) => {
    redEvent.push({
        key: 'LOGOUT_OPTION_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const clickOnFullScreenOption = (data?: any) => {
    redEvent.push({
        key: 'FULL_SCREEN_OPTION_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}
