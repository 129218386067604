import { subMonths } from 'date-fns'

const today = new Date()

const dateObj = {
  from: new Date(subMonths(today, 3)).getTime(),
  to: new Date(today).getTime(),
}

export const orderInitialState = {
  ongoingCases: [],
  fulfilledCases: [],
  refetchSummary: false,
  caseHistoryFilter: {
    searchKeyword: '',
    selectedSource: '',
    dateRange: dateObj,
  },
  status: {
    fetchFulfilledOrders: 'idle',
    fetchOngoingOrders: 'idle',
    fetchCaseHistory: 'idle',
  },
  errors: {
    fetchFulfilledOrders: null,
    fetchOngoingOrders: null,
    fetchCaseHistory: null,
  },
  status_fetch_case_history: 'idle',
  selectedOrderId: null,
  caseHistory: [],
}

export const fiveGVehicles = [
  'TS09UC8429DEMO',
  'TG07T5497',
  'TS09UC2343',
  'TS09UC1234',
  'TS09AB001',
  'TS09UC8429',
  "TS09UD5640",
  'TN09CZ4678',
]



/*

{
  orderId: {
    alerts: [
      {
        type: '',
        read: true/false,
        payload: {

        }
      }
    ]
  },
  orderId2: {
    
  }
}

*/